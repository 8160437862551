import Vue from 'vue';
import VueNotifications from 'vue-notifications';
// import radiusPollingService from './radiusPollingService';

export default {
  // getSNMPStatistic(ctx) {
  //   ctx.SNMPStatistic = [];
  //   const params = {
  //     // action: 'R'
  //   };
  //   ctx.$store.commit('loadingSNMPStatBegin');
  //   Vue.axios.get(`${window.RESTAPI || ''}/api/monitor/cpe_report`, { params }).then(
  //     (response) => {
  //       if (response.data.status === 'success') {
  //         // console.log(response.data.data.itemslist);
  //         ctx.SNMPStatistic = JSON.parse(JSON.stringify(response.data.data.itemslist));
  //         // дальше пробегаемся по всему списку, чтобы найти минимальную дату
  //         // и отрисовать ее в графе FROM в datepicker'e
  //         let minTimestamp = ctx.drPickerDate.startDate;
  //         for (const statisticObject of ctx.SNMPStatistic) {
  //           let statisticObjectTimeStampInJSFormat;
  //           if (Number.isInteger(statisticObject.time_stamp)) {
  //             statisticObjectTimeStampInJSFormat = statisticObject.time_stamp * 1000;
  //           } else {
  //             // если почему то ts не число, а скажем null, то считаем что он равен
  //             // дефолтному значению FROM в datepicker'e
  //             statisticObjectTimeStampInJSFormat = ctx.drPickerDate.startDate;
  //           }
  //           if (statisticObjectTimeStampInJSFormat < minTimestamp) {
  //             minTimestamp = statisticObjectTimeStampInJSFormat;
  //           }
  //         }
  //         // находим таймстеп на  начало дня
  //         const minTimestampBeginOfTheDayDate = new Date(minTimestamp);
  //         minTimestampBeginOfTheDayDate.setHours(0, 0, 0, 0);
  //         ctx.drPickerDate.startDate = minTimestampBeginOfTheDayDate.getTime();
  //         ctx.redrawDatePicker();
  //       } else if (response.data.status === 'error') {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('loadingSNMPStatEnd');
  //     },
  //     (err) => {
  //       ctx.$store.commit('loadingSNMPStatEnd');
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //     }
  //   );
  // },
  getSNMPStatistic(ctx) {
    ctx.SNMPStatistic = [];
    const params = {
      // action: 'R'
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };
    if (ctx.filters.locationId === '' || ctx.filters.location === 'All locations') {
        // console.log('here')
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.locationId;
        params.with_childs = ctx.filters.with_childs;
    }
    ctx.$store.commit('loadingSNMPStatBegin');
    Vue.axios.get(`${window.RESTAPI || ''}/api/monitor/cpe_report`, { params }).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          ctx.SNMPStatistic = JSON.parse(JSON.stringify(response.data.data.itemslist));
          // дальше пробегаемся по всему списку, чтобы найти минимальную дату
          // и отрисовать ее в графе FROM в datepicker'e
          let minTimestamp = ctx.drPickerDate.startDate;
          for (const statisticObject of ctx.SNMPStatistic) {
            let statisticObjectTimeStampInJSFormat;
            if (Number.isInteger(statisticObject.time_stamp)) {
              statisticObjectTimeStampInJSFormat = statisticObject.time_stamp * 1000;
            } else {
              // если почему то ts не число, а скажем null, то считаем что он равен
              // дефолтному значению FROM в datepicker'e
              statisticObjectTimeStampInJSFormat = ctx.drPickerDate.startDate;
            }
            if (statisticObjectTimeStampInJSFormat < minTimestamp) {
              minTimestamp = statisticObjectTimeStampInJSFormat;
            }
          }
          // находим таймстеп на  начало дня
          const minTimestampBeginOfTheDayDate = new Date(minTimestamp);
          minTimestampBeginOfTheDayDate.setHours(0, 0, 0, 0);
          ctx.drPickerDate.startDate = minTimestampBeginOfTheDayDate.getTime();
          ctx.redrawDatePicker();
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPStatEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPStatEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  getSNMPStatisticMock(ctx) {
    ctx.SNMPStatistic = [
      {
        id: '13e16758-20d5-9cd3-e181-ec4c4d57b1db',
        name: 'nap-221-22',
        nas_ip: '10.30.40.50',
        client: 'SPB_32321321',
        address: '/spb_golycina/',
        availability: true,
        uptime: 160,
        time_stamp: 1586898000,
        associated_users: 5,
        wlans: ['Beeline_WiFi_Free', 'SPB_Staff'],
        traffic_tx_volume: 500,
        traffic_rx_volume: 400,
        traffic_tx_speed: 1.5,
        traffic_rx_speed: 3.4
      },
      {
        id: '13e16555-20d5-9cd3-e181-ec4c4d57b1db',
        name: 'another point',
        nas_ip: '7.7.40.50',
        client: 'ANotherCLient_32321321',
        address: '/cool_loc/another_location/',
        availability: false,
        uptime: 3000,
        time_stamp: 1586899000,
        associated_users: 15,
        wlans: ['Cool_WifI-Wlan', 'AnotherWlan', 'dasdasd'],
        traffic_tx_volume: 20000,
        traffic_rx_volume: 100,
        traffic_tx_speed: 2000000,
        traffic_rx_speed: 2.1
      },
      {
        id: '13e16555-111-9cd3-e181-ec4c4d57b1db',
        name: 'another point 2',
        nas_ip: '1.7.40.50',
        client: 'CLient_32321321',
        address: '/cool_loc/',
        availability: true,
        uptime: 30000,
        time_stamp: 1586877000,
        associated_users: 150,
        wlans: ['Cool_WifI-Wlan', 'AnotherWlan', 'another wifi'],
        traffic_tx_volume: 200000000,
        traffic_rx_volume: 1000000000,
        traffic_tx_speed: 200000000,
        traffic_rx_speed: 300000000
      }
    ];
    // дальше пробегаемся по всему списку, чтобы найти минимальную дату
    // и отрисовать ее в графе FROM в datepicker'e
    let minTimestamp = ctx.drPickerDate.startDate;
    for (const statisticObject of ctx.SNMPStatistic) {
      let statisticObjectTimeStampInJSFormat;
      if (Number.isInteger(statisticObject.time_stamp)) {
        statisticObjectTimeStampInJSFormat = statisticObject.time_stamp * 1000;
      } else {
        // если почему то ts не число, а скажем null, то считаем что он равен
        // дефолтному значению FROM в datepicker'e
        statisticObjectTimeStampInJSFormat = ctx.drPickerDate.startDate;
      }
      if (statisticObjectTimeStampInJSFormat < minTimestamp) {
        minTimestamp = statisticObjectTimeStampInJSFormat;
      }
    }
    // находим таймстеп на  начало дня
    const minTimestampBeginOfTheDayDate = new Date(minTimestamp);
    minTimestampBeginOfTheDayDate.setHours(0, 0, 0, 0);
    ctx.drPickerDate.startDate = minTimestampBeginOfTheDayDate.getTime();
    ctx.redrawDatePicker();
  }
};
